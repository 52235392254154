<template>

  <div v-if="useAuth().isAuth() && !useAuth().isVerifiedEmail()" :class="'email_conf ' + (isLoding ? 'gloading' : '')"> 
    {{ $t('comments_view_resend_confirm_1') }}
    <span @click="resend">
      {{ $t('comments_view_resend_confirm_2') }}
    </span>
  </div> 
  
</template>

<script setup>

const { t } = useI18n();
const { $api, $popup } = useNuxtApp();

const isLoding = ref(false)
 

const resend = async () => {

  if(!confirm(t('comments_view_send_email_confirmation_title') + '.' + t('comments_view_send_email_confirmation_text'))){
    return false;
  }



  isLoding.value = true;
  $api
    .postGrowerResendVerificationEmail()
    .then(() => {
      isLoding.value = false;
      $popup.success(t('signup_view_email_sending'))
    })
    .catch((err) => {
      isLoding.value = false;
      $popup.error(err.message)
    })
}

</script>

<style scoped>
 
.email_conf {
  color: black;
  padding: 10px 20px;
  width: 100%;
  max-width: 100%;
  font-size: 0.75rem;
  background-color: #ffdcda;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
  position: relative;
}
.email_conf span {
  text-decoration: underline;
  color: #770f01;
  cursor: pointer;
}

</style>
